import { RefinancingWaitingApprovalData, Product } from './../interfaces/'
import { formatCurrency } from '../../../helpers/'

const adaptRefinancingWaitingApprovalCard = (
  data: Product,
): RefinancingWaitingApprovalData => {
  return {
    amount: formatCurrency('pt-BR', data.amount),
    netValue: formatCurrency('pt-BR', data.net_value),
    totalInstallments: data.total_installments,
    seePreviousItems: data?.buttons.see_previous_items,
    seePreviousItemsHref: '/consignado/loan-details',
  }
}

export { adaptRefinancingWaitingApprovalCard }
