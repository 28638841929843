import React from 'react'
import { styled } from '@creditas-ui/system'
import { IconEye_5 } from '@creditas-ui/icons'
import { GlobalIcons } from '@creditas-ui/global-icons'
import { cardBtnRedirect } from './card.style'

const Button = styled.button`
  ${cardBtnRedirect}
`

interface Props {
  callbackLocation: any
}

const CardBtnRedirect: React.FC<Props> = ({ callbackLocation }) => {
  return (
    <Button onClick={callbackLocation}>
      <GlobalIcons Icon={IconEye_5} size="large" />
    </Button>
  )
}

export { CardBtnRedirect }
