export enum ProductType {
  Requesting = 'REQUESTING',
  Discharging = 'DISCHARGING',
  Renegotiation = 'RENEGOTIATION',
  SalaryAdvance = 'SALARY_ADVANCE',
  Product = 'PRODUCT',
  Refinancing = 'REFINANCING',
  CreditCard = 'CREDIT_CARD',
}

export enum ProductStatus {
  Paid = 'PAID',
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Reproved = 'REPROVED',
}

export enum InstallmentStatus {
  Paid = 'PAID',
  Opened = 'OPENED',
}
