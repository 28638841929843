import React from 'react'
import { IconAlarmClockTimeLoading } from '@creditas-ui/icons'
import { ShapeIcons } from '@creditas-ui/shape-icons'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardTitle,
  CardDescription,
  CardGrid,
  CardContent,
  CardIcon,
  CardResume,
  CardResumeBox,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
  ButtonPrimary,
} from '../Card'

import { WaitingApprovalCardLoanData } from '../../interfaces'

interface Props {
  data: WaitingApprovalCardLoanData
}

const LoanWaitingApprovalCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardGrid>
        <CardIcon>
          <ShapeIcons Icon={IconAlarmClockTimeLoading} size="small" />
        </CardIcon>
        <CardContent>
          <CardTitle
            color="neutral.90"
            variant="headingSmMedium"
            component="h1"
          >
            {t('cards.LoanWaitingApprovalCard.title')}
          </CardTitle>
          <CardDescription color="neutral.60" variant="bodyMdLight">
            {t('cards.LoanWaitingApprovalCard.description')}
          </CardDescription>
          <CardResume>
            <CardResumeBox>
              <CardResumeBoxLabel
                color="neutral.90"
                variant="bodySmBold"
                component="h3"
              >
                {t('cards.LoanWaitingApprovalCard.label1')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight
                variant="headingSmMedium"
                color="primary.80"
              >
                {data.amount}
              </CardResumeBoxHighlight>
            </CardResumeBox>

            <CardResumeBox isRight={true}>
              <CardResumeBoxLabel
                color="neutral.90"
                variant="bodySmBold"
                component="h3"
              >
                {t('cards.LoanWaitingApprovalCard.label2')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight
                variant="headingSmMedium"
                color="primary.80"
              >
                {data.totalInstallments}
              </CardResumeBoxHighlight>
            </CardResumeBox>
          </CardResume>
        </CardContent>
      </CardGrid>
      <ButtonPrimary
        onClick={() => window.singleSpaNavigate(data.redirectTo)}
        marginTop={31}
      >
        {t('cards.LoanWaitingApprovalCard.action')}
      </ButtonPrimary>
    </Card>
  )
}

export { LoanWaitingApprovalCard }
