import { styled } from '@creditas-ui/system'
import { Box, selectTheme } from '@creditas-ui/react'

const Card = styled(Box)`
  background-color: white;
  border: 1px solid ${selectTheme('colors.neutral.20')};
  border-radius: 4px;
  color: ${selectTheme('colors.neutral.10')};
  padding: 24px;
  position: relative;
  width: 100%;
  margin-bottom: 56px;

  @media (max-width: 500px) {
    padding: 15px;
  }
`

export { Card }
