import { Flex, selectTheme } from '@creditas-ui/react'
import { styled } from '@creditas-ui/system'

export const CardGrid = styled.div`
  display: flex;
  align-items: center;
`

export const CardIconContainer = styled.div`
  > svg {
    font-size: 50px;
  }
`

export const CardTitleContainer = styled.div`
  margin-left: 8px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 5px;

  i > svg > g {
    stroke: ${selectTheme('colors.primary.80')};
  }

  > h3 {
    margin-left: 5px;
  }
`

export const ContactsContainer = styled(Flex)`
  flex-direction: row;
  margin-top: 10px;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`
