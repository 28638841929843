import { Product, DeniedCardLoanData } from './../interfaces/'

const adaptLoanDeniedCard = ({ buttons }: Product): DeniedCardLoanData => {
  return {
    seePreviousItems: buttons?.see_previous_items,
    seePreviousItemsHref: '/consignado/loan-details',
  }
}

export { adaptLoanDeniedCard }
