import { ProductStatus, ProductType } from './../../../../enums'
import { Product } from '../../interfaces'

export function isLoanContinueSolicitationCard({
  type,
  status,
}: Product): boolean {
  const solicitationTypes = [ProductType.Requesting, ProductType.Refinancing]
  return (
    solicitationTypes.includes(type) &&
    status === ProductStatus.PendingFormalization
  )
}
