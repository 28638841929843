import { calcProgressBar, resumeInstallmentsFormatted } from '../helpers'
import { CurrentCardStoreData, Product } from '../interfaces'

const adaptStoreCurrentCard = (data: Product): CurrentCardStoreData => {
  const objResumeInstallments = resumeInstallmentsFormatted(data, 'pt-BR')

  const { description, installments, buttons, con_codigo } = data
  return {
    productDescription: description,
    urlProductInternal: '/consignado/loan-details',
    progressBarPercentage: calcProgressBar(installments),
    ...objResumeInstallments,
    buttons: {
      installmentsAdvance: buttons.installments_advance,
      installmentsAdvanceUrl: `/consignado/early-payment/${con_codigo}`,
    },
  }
}

export { adaptStoreCurrentCard }
