import { calcProgressBar, resumeInstallmentsFormatted } from '../helpers'
import { CurrentCardLoanData, Product } from '../interfaces'

const adaptLoanCurrentCard = (data: Product): CurrentCardLoanData => {
  const objResumeInstallments = resumeInstallmentsFormatted(data, 'pt-BR')
  const {
    installments,
    buttons,
    con_codigo,
    has_restructured_installment,
  } = data

  return {
    urlProductInternal: '/consignado/loan-details',
    progressBarPercentage: calcProgressBar(installments),
    hasRestructuredInstallment: has_restructured_installment,
    ...objResumeInstallments,
    buttons: {
      installmentsAdvance: buttons.installments_advance,
      installmentsAdvanceUrl: `/consignado/early-payment/${con_codigo}`,
      refinance: buttons.refinance,
      refinanceUrl: '/consignado/new-simulation',
    },
  }
}

export { adaptLoanCurrentCard }
