import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardTitle,
  CardResume,
  CardResumeBox,
  CardDescription,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
  ButtonPrimary,
} from '../Card'

import { ContinueSolicitationCardLoan } from '../../interfaces'

interface Props {
  data: ContinueSolicitationCardLoan
}

const LoanContinueSolicitationCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardTitle color="neutral.90" variant="headingSmMedium" component="h1">
        {t('cards.LoanContinueSolicitationCard.title')}
      </CardTitle>
      <CardDescription color="neutral.60" variant="bodyMdLight">
        {t('cards.LoanContinueSolicitationCard.description')}
      </CardDescription>
      <CardResume>
        <CardResumeBox>
          <CardResumeBoxLabel
            color="neutral.90"
            variant="bodySmBold"
            component="h3"
          >
            {t('cards.LoanContinueSolicitationCard.label1')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight variant="headingSmMedium" color="primary.80">
            {data.amount}
          </CardResumeBoxHighlight>
        </CardResumeBox>

        <CardResumeBox>
          <CardResumeBoxLabel
            color="neutral.90"
            variant="bodySmBold"
            component="h3"
          >
            {t('cards.LoanContinueSolicitationCard.label2')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight variant="headingSmMedium" color="primary.80">
            {data.totalInstallments}
          </CardResumeBoxHighlight>
        </CardResumeBox>
      </CardResume>

      <ButtonPrimary
        onClick={() => window.singleSpaNavigate(data.redirectTo)}
        marginTop={24}
      >
        {t('cards.LoanContinueSolicitationCard.action')}
      </ButtonPrimary>
    </Card>
  )
}

export { LoanContinueSolicitationCard }
