import React from 'react'

import { ProductCards } from './ProductCards'
import { TranslatedErrorBoundary } from '../../components'

import { setUserContext } from '../../utils'
import tokens from '@creditas/tokens'
import { ThemeProvider as LegacyThemeProvider } from '@creditas/stylitas'
import { ThemeProvider, standard } from '@creditas-ui/react'
import { getSpecificContentCard } from './services/'
import { RequestManager } from '../../requests'
import { DashboardData } from '../../interfaces/Dashboard'

interface Props {
  api: string
  language: string
  authToken: string
  clientId: string
  data: DashboardData
}

interface State {
  componentData: any
  seePreviousItems: boolean
}

class App extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      componentData: [],
      seePreviousItems: false,
    }
  }

  componentDidMount() {
    const { api, authToken, clientId } = this.props
    RequestManager.url = api
    RequestManager.authToken = authToken
    setUserContext({ id: clientId })

    this.fetchAPIData()
  }

  async fetchAPIData() {
    const APIData = this.props.data

    const { available_products, products } = APIData

    const state = { ...this.state }
    if (available_products?.length) {
      state.componentData.push(...available_products)
      state.seePreviousItems = !!products?.length
    }

    if (products?.length) {
      state.componentData.push(...products)
    }

    this.setState({ ...state })
  }

  dispatchNotification(componentId: string, componentData: any) {
    const notificationsId = {
      loanAcquittanceCard: 'pendingAcquittanceLoanBillet',
    }

    const id = notificationsId[componentId]
    if (!id) {
      return
    }

    const event = new CustomEvent('notifications', {
      detail: {
        id: notificationsId[componentId],
        data: componentData,
      },
    })

    document.dispatchEvent(event)
  }

  componentDidCatch() {}

  render() {
    const { componentData } = this.state

    return (
      <TranslatedErrorBoundary>
        <LegacyThemeProvider theme={{ ...tokens }}>
          <ThemeProvider theme={standard}>
            {componentData.map((data, i) => {
              if (!data) return
              const { componentId, componentData } = getSpecificContentCard(
                data,
              )
              this.dispatchNotification(componentId, componentData)
              return (
                <ProductCards
                  key={i}
                  componentId={componentId}
                  componentData={componentData}
                />
              )
            })}
          </ThemeProvider>
        </LegacyThemeProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
