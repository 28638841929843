import { AvailableProduct, Product } from './../../interfaces'
import { isLoanAvailableCard } from './isLoanAvailableCard'
import { isLoanAcquittanceCard } from './isLoanAcquittanceCard'
import { isLoanWaitingApprovalCard } from './isLoanWaitingApprovalCard'
import { handleComponentId, ValidationRule } from '../../../../helpers'
import { isLoanDeniedCard } from './isLoanDeniedCard'
import { isLoanCurrentCard } from './isLoanCurrentCard'
import { adaptComponentData } from '../../adapters'
import { isStoreCurrentCard } from './isStoreCurrentCard'
import { isRefinancingWaitingApproval } from './isRefinancingWaitingApproval'
import { isLoanContinueSolicitationCard } from './isLoanContinueSolicitationCard'
import { isLoanTerminatedCard } from './isLoanTerminatedCard'

interface Params {
  componentId: string
  componentData: unknown
}

function getSpecificContentCard(data: Product | AvailableProduct): Params {
  const arrValidationRules: Array<ValidationRule> = [
    [isLoanTerminatedCard, 'loanTerminatedCard'],
    [isLoanAcquittanceCard, 'loanAcquittanceCard'],
    [isLoanWaitingApprovalCard, 'loanWaitingApprovalCard'],
    [isLoanDeniedCard, 'loanDeniedCard'],
    [isLoanCurrentCard, 'loanCurrentCard'],
    [isStoreCurrentCard, 'storeCurrentCard'],
    [isRefinancingWaitingApproval, 'refinancingWaitingApprovalCard'],
    [isLoanContinueSolicitationCard, 'loanContinueSolicitationCard'],
    [isLoanAvailableCard, 'loanAvailableCard'],
  ]

  const componentId = handleComponentId(data, arrValidationRules)
  const componentData = adaptComponentData(data, componentId) || false

  return {
    componentId,
    componentData,
  }
}

export { getSpecificContentCard }
