import { ButtonSecondary as ActionLink } from '@creditas-ui/button'
import { Flex, selectTheme } from '@creditas-ui/react'
import { styled } from '@creditas-ui/system'

export const SeeInstallmentsButton = styled(ActionLink)`
  > i > svg > path:nth-child(1) {
    stroke: ${selectTheme('colors.primary.60')};
  }
`

export const BtnsBox = styled(Flex)`
  justify-content: space-around;
  margin-top: 24px;
  width: 100%;

  @media (max-width: 545px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  > button {
    min-width: 240px;
    justify-content: center;

    @media (max-width: 545px) {
      min-width: 100%;

      &:first-of-type {
        margin-top: 10px;
        order: 2;
      }

      &:last-of-type {
        order: 1;
      }
    }
  }
`
