import { AvailableProduct, AvailableCardLoanData } from './../interfaces/'
import { formatCurrency } from '../../../helpers/'

const adaptLoanAvailableCard = (
  data: AvailableProduct,
): AvailableCardLoanData => {
  return {
    amount: formatCurrency('pt-BR', data.amount),
    totalInstallments: data.total_installments,
    tax: data.tax,
    redirectTo: '/consignado/simulation',
  }
}

export { adaptLoanAvailableCard }
