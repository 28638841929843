import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Card,
  CardTitle,
  CardDescription,
  CardResume,
  CardResumeBox,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
  ButtonPrimary,
} from '../Card'

import { AcquittanceCardLoanData } from '../../interfaces'

interface Props {
  data: AcquittanceCardLoanData
}

const LoanAcquittanceCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardTitle color="neutral.90" variant="headingSmMedium" component="h1">
        {t('cards.LoanAcquittanceCard.title')}
      </CardTitle>
      <CardDescription color="neutral.60" variant="bodyMdLight">
        {t('cards.LoanAcquittanceCard.description')}
      </CardDescription>
      <CardResume>
        <CardResumeBox>
          <CardResumeBoxLabel
            color="neutral.90"
            variant="bodySmBold"
            component="h3"
          >
            {t('cards.LoanAcquittanceCard.label1')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight variant="headingSmMedium" color="primary.80">
            {data.amount}
          </CardResumeBoxHighlight>
        </CardResumeBox>

        <CardResumeBox isRight={true}>
          <CardResumeBoxLabel
            color="neutral.90"
            variant="bodySmBold"
            component="h3"
          >
            {t('cards.LoanAcquittanceCard.label2')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight variant="headingSmMedium" color="primary.80">
            {data.totalInstallments}
          </CardResumeBoxHighlight>
        </CardResumeBox>
      </CardResume>

      <ButtonPrimary
        onClick={() => window.singleSpaNavigate(data.redirectTo)}
        marginTop={24}
      >
        {t('cards.LoanAcquittanceCard.action')}
      </ButtonPrimary>
    </Card>
  )
}

export { LoanAcquittanceCard }
