import { Product } from '../../interfaces'
import { ProductType, ProductStatus } from '../../enums/index'

export function isLoanDeniedCard({ type, status }: Product): boolean {
  return (
    (type === ProductType.Requesting ||
      type === ProductType.Refinancing ||
      type === ProductType.Discharging) &&
    status === ProductStatus.Reproved
  )
}
