import React from 'react'
import { ButtonSecondary } from '@creditas-ui/button'
import { IconArrow5 } from '@creditas-ui/icons'
import { GlobalIcons } from '@creditas-ui/global-icons'
import { useTranslation } from 'react-i18next'
import { Card, CardTitle, CardInfosContainer, ButtonPrimary } from '../Card'
import { ProgressBox } from '..'
import { CurrentCardLoanData } from '../../interfaces'
import { Line, TextAndSubtext } from '../../../../components'
import { InformationCard } from '../InformationCard/InformationCard'
import { PAYROLL_DEDUCTION_ISSUE_URL } from '../../../../domain/product-cards/product-cards.constants'
import { BtnsBox, SeeInstallmentsButton } from './loanCurrentCard.styl'
import { envVars } from '../../../../envVars'
import { Progressbar } from '@creditas-ui/react'

interface Props {
  data: CurrentCardLoanData
}

const LoanCurrentCard = ({ data }: Props) => {
  const { t } = useTranslation()
  const canAdvance =
    data.buttons?.installmentsAdvance && data.totalInstallmentsOpened > 1

  const redirectTo = (url: string) => {
    window.singleSpaNavigate(url)
  }

  const shouldShowInformationCard = envVars.showRestructuredInstallmentAlert

  return (
    <Card>
      <CardTitle color="neutral.90" variant="headingSmMedium" component="h1">
        {t('cards.LoanCurrentCard.title')}
      </CardTitle>

      <ProgressBox>
        <Progressbar progress={data.progressBarPercentage} />
      </ProgressBox>

      <CardInfosContainer flexDirection="column">
        <TextAndSubtext
          text={t('cards.LoanCurrentCard.label1')}
          subtext={t('cards.LoanCurrentCard.installments', {
            totalInstallmentsPaid: data.totalInstallmentsPaid,
            totalInstallments:
              data.totalInstallmentsPaid + data.totalInstallmentsOpened,
          })}
        />
        <TextAndSubtext
          text={t('cards.LoanCurrentCard.label2')}
          subtext={data.amountPaid}
        />
        <TextAndSubtext
          text={t('cards.LoanCurrentCard.label3')}
          subtext={data.amountOpened}
        />
        {shouldShowInformationCard && data.hasRestructuredInstallment && (
          <InformationCard
            text={t('cards.LoanCurrentCard.informationCard.text')}
            linkText={t('cards.LoanCurrentCard.informationCard.link')}
            linkHandler={() =>
              window.open(
                PAYROLL_DEDUCTION_ISSUE_URL,
                '_blank',
                'noopener noreferrer',
              )
            }
          />
        )}
      </CardInfosContainer>

      <SeeInstallmentsButton
        color="#3377BB"
        onClick={_ => redirectTo(data.urlProductInternal)}
      >
        {t('cards.LoanCurrentCard.actionLink')}
        <GlobalIcons Icon={IconArrow5} size="medium" />
      </SeeInstallmentsButton>

      <Line marginTop={24} />

      <BtnsBox>
        {canAdvance && (
          <ButtonSecondary
            size="large"
            onClick={_ => redirectTo(data.buttons.installmentsAdvanceUrl)}
          >
            {t('cards.LoanCurrentCard.actionSecondary')}
          </ButtonSecondary>
        )}

        {data.buttons?.refinance && (
          <ButtonPrimary onClick={_ => redirectTo(data.buttons.refinanceUrl)}>
            {t('cards.LoanCurrentCard.action')}
          </ButtonPrimary>
        )}
      </BtnsBox>
    </Card>
  )
}

export { LoanCurrentCard }
