import React from 'react'
import { IconAlarmClockTimeLoading } from '@creditas-ui/icons'
import { ShapeIcons } from '@creditas-ui/shape-icons'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardTitle,
  CardDescription,
  CardGrid,
  CardContent,
  CardIcon,
  CardResume,
  CardResumeBox,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
  CardBtnSeePreviousItem,
} from '../Card'

import { RefinancingWaitingApprovalData } from '../../interfaces'

interface Props {
  data: RefinancingWaitingApprovalData
}

const RefinancingWaitingApprovalCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardGrid>
        <CardIcon>
          <ShapeIcons Icon={IconAlarmClockTimeLoading} size="small" />
        </CardIcon>
        <CardContent>
          <CardTitle
            color="neutral.90"
            variant="headingSmMedium"
            component="h1"
          >
            {t('cards.RefinancingWaitingApprovalCard.title')}
          </CardTitle>
          <CardDescription color="neutral.60" variant="bodyMdLight">
            {t('cards.RefinancingWaitingApprovalCard.description')}
          </CardDescription>
          <CardResume>
            <CardResumeBox>
              <CardResumeBoxLabel
                color="neutral.90"
                variant="bodySmBold"
                component="h3"
              >
                {t('cards.RefinancingWaitingApprovalCard.label1')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight
                variant="headingSmRegular"
                color="neutral.90"
              >
                {data.amount}
              </CardResumeBoxHighlight>
            </CardResumeBox>

            <CardResumeBox isRight={true}>
              <CardResumeBoxLabel
                color="neutral.90"
                variant="bodySmBold"
                component="h3"
              >
                {t('cards.RefinancingWaitingApprovalCard.label2')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight
                variant="headingSmRegular"
                color="neutral.90"
              >
                {data.totalInstallments}
              </CardResumeBoxHighlight>
            </CardResumeBox>
          </CardResume>

          <CardResume>
            <CardResumeBox>
              <CardResumeBoxLabel
                color="neutral.90"
                variant="bodySmBold"
                component="h3"
              >
                {t('cards.RefinancingWaitingApprovalCard.label3')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight
                variant="headingSmMedium"
                color="primary.80"
              >
                {data.netValue}
              </CardResumeBoxHighlight>
            </CardResumeBox>
          </CardResume>
        </CardContent>
      </CardGrid>

      {data.seePreviousItems && (
        <CardBtnSeePreviousItem
          onClick={_ => window.singleSpaNavigate(data.seePreviousItemsHref)}
        >
          {t('cards.RefinancingWaitingApprovalCard.action')}
        </CardBtnSeePreviousItem>
      )}
    </Card>
  )
}

export { RefinancingWaitingApprovalCard }
