import { Product, WaitingApprovalCardLoanData } from '../interfaces'
import { formatCurrency } from '../../../helpers'

const adaptLoanWaitingApprovalCard = ({
  amount,
  total_installments,
}: Product): WaitingApprovalCardLoanData => {
  return {
    amount: formatCurrency('pt-BR', amount),
    totalInstallments: total_installments,
    redirectTo: '/consignado/simulation',
  }
}

export { adaptLoanWaitingApprovalCard }
