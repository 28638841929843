import { styled } from '@creditas-ui/react'

const CardIcon = styled.div`
  > svg {
    @media (max-width: 500px) {
      font-size: 72px;
    }
  }
`

const CardContent = styled.div`
  margin-left: 40px;

  @media (max-width: 500px) {
    margin: 8px 0 0 0;
  }
`

const CardGrid = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`

export { CardGrid, CardIcon, CardContent }
