import React from 'react'
import { Button } from '@creditas/button'

import {
  Card,
  CardTitle,
  CardResume,
  CardSubtitle,
  CardResumeBox,
  CardBtnRedirect,
  CardResumeBoxInfo,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
} from '../Card'

import { ProgressBox } from '..'
import { BtnsBox } from './storeCurrentCard.style'
import { useTranslation, Trans } from 'react-i18next'
import { CurrentCardStoreData } from '../../interfaces'
import { ButtonSecondary, Progressbar } from '@creditas-ui/react'

interface Props {
  data: CurrentCardStoreData
}

const StoreCurrentCard = ({ data }: Props) => {
  const { t } = useTranslation()

  const redirectTo = (url: string) => {
    window.singleSpaNavigate(url)
  }

  return (
    <Card>
      <CardTitle color="neutral.90" variant="headingSmMedium" component="h1">
        {t('cards.StoreCurrentCard.title')}
      </CardTitle>
      <CardSubtitle color="neutral.90" variant="bodyMdLight">
        {data.productDescription}
      </CardSubtitle>

      <CardBtnRedirect
        callbackLocation={_ => redirectTo(data.urlProductInternal)}
      />

      <ProgressBox>
        <Progressbar progress={data.progressBarPercentage} />
      </ProgressBox>

      <CardResume>
        <CardResumeBox>
          <CardResumeBoxLabel
            color="neutral.90"
            variant="bodySmBold"
            component="h3"
          >
            <Trans i18nKey="cards.StoreCurrentCard.label1">
              <span></span>
            </Trans>
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight variant="headingSmMedium" color="primary.80">
            {data.totalInstallmentsPaid}
          </CardResumeBoxHighlight>
          <CardResumeBoxInfo color="neutral.80" variant="bodyMdMedium">
            {data.amountPaid}
          </CardResumeBoxInfo>
        </CardResumeBox>

        <CardResumeBox isRight={true}>
          <CardResumeBoxLabel
            color="neutral.90"
            variant="bodySmBold"
            component="h3"
          >
            <Trans i18nKey="cards.StoreCurrentCard.label2">
              <span></span>
            </Trans>
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight variant="headingSmMedium" color="primary.80">
            {data.totalInstallmentsOpened}
          </CardResumeBoxHighlight>
          <CardResumeBoxInfo color="neutral.80" variant="bodyMdMedium">
            {data.amountOpened}
          </CardResumeBoxInfo>
        </CardResumeBox>
      </CardResume>

      <BtnsBox>
        {data.buttons?.installmentsAdvance && (
          <ButtonSecondary
            onClick={_ => redirectTo(data.buttons.installmentsAdvanceUrl)}
          >
            {t('cards.StoreCurrentCard.action')}
          </ButtonSecondary>
        )}
      </BtnsBox>
    </Card>
  )
}

export { StoreCurrentCard }
