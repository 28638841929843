import React from 'react'

import {
  LoanDeniedCard,
  LoanCurrentCard,
  StoreCurrentCard,
  LoanAvailableCard,
  LoanTerminatedCard,
  LoanAcquittanceCard,
  LoanWaitingApprovalCard,
  RefinancingWaitingApprovalCard,
  LoanContinueSolicitationCard,
} from './components'

interface Props {
  componentId: string
  componentData: Array<any>
}

const ProductCards = ({ componentId, componentData }) => {
  const renderComponent = {
    loanAvailableCard: <LoanAvailableCard data={componentData} />,
    loanAcquittanceCard: <LoanAcquittanceCard data={componentData} />,
    loanDeniedCard: <LoanDeniedCard data={componentData} />,
    loanWaitingApprovalCard: <LoanWaitingApprovalCard data={componentData} />,
    loanCurrentCard: <LoanCurrentCard data={componentData} />,
    storeCurrentCard: <StoreCurrentCard data={componentData} />,
    refinancingWaitingApprovalCard: (
      <RefinancingWaitingApprovalCard data={componentData} />
    ),
    loanContinueSolicitationCard: (
      <LoanContinueSolicitationCard data={componentData} />
    ),
    loanTerminatedCard: <LoanTerminatedCard />,
  }

  return <>{renderComponent[componentId] || ''}</>
}

export { ProductCards }
