import { Flex, getTypography, styled } from '@creditas-ui/react'

export const BtnsBox = styled(Flex)`
  justify-content: center;
  margin-top: 15px;
  width: 100%;

  > button {
    ${getTypography('headingXsMedium')}

    justify-content: center;
    min-width: 240px;

    @media (max-width: 545px) {
      min-width: 100%;
    }
  }
`
