import { Product } from '../../interfaces'
import { ProductType, ProductStatus } from '../../enums'

export function isLoanCurrentCard({ type, status }: Product): boolean {
  return (
    (type === ProductType.Requesting ||
      type === ProductType.Refinancing ||
      type === ProductType.Discharging) &&
    status === ProductStatus.Approved
  )
}
