import { AcquittanceCardLoanData, Product } from './../interfaces/'
import { formatCurrency } from '../../../helpers/'

const adaptLoanAcquittanceCard = (data: Product): AcquittanceCardLoanData => {
  const { con_codigo } = data
  return {
    amount: formatCurrency('pt-BR', data.amount),
    totalInstallments: data.total_installments,
    redirectTo: `/consignado/acquittance/${con_codigo}`,
  }
}

export { adaptLoanAcquittanceCard }
