import { css, selectTheme } from '@creditas-ui/react'

export const cardResume = () => css`
  justify-content: space-between;
  font-weight: 600;
  margin-top: 24px;

  @media (max-width: 500px) {
    margin-top: 16px;
  }
`

export const cardResumeBox = () => css`
  flex-direction: column;

  &:first-of-type {
    margin-right: 5px;
  }
`

export const cardBtnRedirect = ({ theme }) => css`
  outline: none;
  cursor: pointer;
  user-select: none;
  background: none;
  position: absolute;
  top: 15px;
  right: 8px;
  border: 0;

  @media (max-width: 500px) {
    top: 8px;
    right: 0px;
  }

  i > svg > g {
    stroke: ${selectTheme('colors.primary.60')({ theme })};
    font-size: 48px;
  }
`
