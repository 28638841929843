import React from 'react'
import BInfoPositive from '@creditas/brand-icons/dist/cS/BInfoPositive'
import { useTranslation } from 'react-i18next'
import { Internet, Notification } from '@creditas/icons'
import {
  IconInfoInfornation,
  IconMailEmailLetter3,
  IconPhoneCall,
} from '@creditas-ui/icons'
import { ShapeIcons } from '@creditas-ui/shape-icons'
import { GlobalIcons } from '@creditas-ui/global-icons'

import {
  Card,
  CardTitle,
  CardDescription,
  CardResume,
  CardResumeBoxLabel,
  ButtonPrimary,
} from '../Card'
import {
  ContactsContainer,
  IconContainer,
  CardGrid,
  CardIconContainer,
  CardTitleContainer,
} from './loanTerminatedCard.style'

const LoanTerminatedCard = () => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardGrid>
        <CardIconContainer>
          <ShapeIcons Icon={IconInfoInfornation} size="small" />
        </CardIconContainer>
        <CardTitleContainer>
          <CardTitle
            color="neutral.90"
            variant="headingSmMedium"
            component="h1"
          >
            {t('cards.LoanTerminatedCard.title')}
          </CardTitle>
        </CardTitleContainer>
      </CardGrid>

      <CardDescription color="neutral.60" variant="bodyMdRegular">
        {t('cards.LoanTerminatedCard.description')}
      </CardDescription>

      <CardDescription color="neutral.80" variant="bodyMdRegular">
        {t('cards.LoanTerminatedCard.label1')}
      </CardDescription>

      <ContactsContainer>
        <IconContainer>
          <GlobalIcons Icon={IconPhoneCall} size="large" />
          <CardResumeBoxLabel
            color="neutral.80"
            variant="bodyMdBold"
            component="h3"
          >
            11 3164-1402
          </CardResumeBoxLabel>
        </IconContainer>
        <IconContainer>
          <GlobalIcons Icon={IconMailEmailLetter3} size="large" />
          <CardResumeBoxLabel
            color="neutral.80"
            variant="bodyMdBold"
            component="h3"
          >
            atendimento@creditas.com.br
          </CardResumeBoxLabel>
        </IconContainer>
      </ContactsContainer>

      <CardResume>
        <CardResumeBoxLabel color="neutral.80" variant="bodyMdRegular">
          {t('cards.LoanTerminatedCard.label2')}
        </CardResumeBoxLabel>
      </CardResume>

      <ButtonPrimary
        onClick={() =>
          window.singleSpaNavigate('https://negocie.creditas.com.br/')
        }
        marginTop={24}
      >
        {t('cards.LoanTerminatedCard.action')}
      </ButtonPrimary>
    </Card>
  )
}

export { LoanTerminatedCard }
