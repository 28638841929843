import { Product, ContinueSolicitationCardLoan } from './../interfaces/'
import { formatCurrency } from '../../../helpers/'

const adaptLoanContinueSolicitationCard = (
  data: Product,
): ContinueSolicitationCardLoan => {
  return {
    amount: formatCurrency('pt-BR', data.amount),
    totalInstallments: data.total_installments,
    redirectTo: '/consignado/contracts',
  }
}

export { adaptLoanContinueSolicitationCard }
