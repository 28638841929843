import { ProductInstallment } from '../interfaces'
import { InstallmentStatus } from '../enums'

const calcProgressBar = (installments: Array<ProductInstallment>): number => {
  const totalPaid = installments.filter(
    item => item.status === InstallmentStatus.Paid,
  ).length

  const totalInstallments = installments.length

  return Math.round((totalPaid / totalInstallments) * 100)
}

export { calcProgressBar }
