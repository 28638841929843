import { styled, css, Flex } from '@creditas-ui/react'
import { Typography } from '@creditas-ui/typography'
import { cardResume, cardResumeBox } from './card.style'

const CardResumeBox = styled(Flex)<{ isRight?: boolean }>`
  ${cardResumeBox}

  ${({ isRight }) =>
    isRight &&
    css`
      text-align: right;
    `}
`

const CardResumeBoxHighlight = styled(Typography)``

const CardResumeBoxLabel = styled(Typography)`
  > span {
    @media (max-width: 400px) {
      display: block;
    }
  }
`

const CardResumeBoxInfo = styled(Typography)``

const CardResume = styled(Flex)`
  ${cardResume}
`

export {
  CardResume,
  CardResumeBox,
  CardResumeBoxInfo,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
}
