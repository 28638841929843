import React from 'react'
import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/react'
import { ButtonSecondary as ActionLink } from '@creditas-ui/button'
import { IconArrow5 } from '@creditas-ui/icons'
import { GlobalIcons } from '@creditas-ui/global-icons'

const CustomActionLink = styled(ActionLink)`
  position: absolute;
  padding: 0;
  right: 0;
  bottom: -40px;

  > i > svg > path:nth-child(1) {
    stroke: ${selectTheme('colors.primary.60')};
  }
`

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const CardBtnSeePreviousItem: React.FC<Props> = ({ onClick, children }) => {
  return (
    <CustomActionLink onClick={onClick}>
      {children}
      <GlobalIcons Icon={IconArrow5} size="medium" />
    </CustomActionLink>
  )
}

export { CardBtnSeePreviousItem }
