import { adaptLoanCurrentCard } from './adaptLoanCurrentCard'
import { adaptStoreCurrentCard } from './adaptStoreCurrentCard'
import { adaptCreditCardCurrentCard } from './adaptCreditCardCurrentCard'
import { adaptRefinancingWaitingApprovalCard } from './adaptRefinancingWaitingApprovalCard'
import { adaptLoanAvailableCard } from './adaptLoanAvailableCard'
import { adaptLoanAcquittanceCard } from './adaptLoanAcquittanceCard'
import { adaptLoanDeniedCard } from './adaptLoanDeniedCard'
import { adaptLoanWaitingApprovalCard } from './adaptLoanWaitingApprovalCard'
import { adaptStoreWaitingApprovalCard } from './adaptStoreWaitingApprovalCard'
import { adaptLoanContinueSolicitationCard } from './adaptLoanContinueSolicitationCard'

const adaptComponentData = (data, componentId: string) => {
  const method =
    {
      loanCurrentCard: adaptLoanCurrentCard,
      storeCurrentCard: adaptStoreCurrentCard,
      creditCardCurrentCard: adaptCreditCardCurrentCard,
      refinancingWaitingApprovalCard: adaptRefinancingWaitingApprovalCard,
      storeWaitingApprovalCard: adaptStoreWaitingApprovalCard,
      loanAvailableCard: adaptLoanAvailableCard,
      loanAcquittanceCard: adaptLoanAcquittanceCard,
      loanDeniedCard: adaptLoanDeniedCard,
      loanWaitingApprovalCard: adaptLoanWaitingApprovalCard,
      loanContinueSolicitationCard: adaptLoanContinueSolicitationCard,
    }[componentId] || false

  return method && method(data)
}

export { adaptComponentData }
