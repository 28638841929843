import React from 'react'
import { GlobalIcons } from '@creditas-ui/global-icons'
import { IconWarningRetryAlert_1 } from '@creditas-ui/icons'
import {
  InformationCardContainer,
  TextContainer,
  TextContent,
  LinkContent,
} from './InformationCard.styles'

interface InformationCardProps {
  text: string
  linkText: string
  linkHandler: () => void
}

export const InformationCard = ({
  text = '',
  linkText = '',
  linkHandler,
}: InformationCardProps) => {
  return (
    <InformationCardContainer bg="warning.40">
      <TextContainer>
        <TextContent variant="bodySmRegular" color="neutral.60">
          {text} <LinkContent onClick={linkHandler}>{linkText}</LinkContent>
        </TextContent>
      </TextContainer>
      <GlobalIcons Icon={IconWarningRetryAlert_1} size="large" />
    </InformationCardContainer>
  )
}
