import { InstallmentStatus } from '../enums'
import {
  Product,
  ResumeInstallments,
  ResumeInstallmentsFormatted,
} from '../interfaces'
import { formatCurrency } from '../../../helpers/'

const resumeInstallments = (product: Product): ResumeInstallments => {
  const { installments } = product
  const total: ResumeInstallments = {
    amountPaid: 0,
    amountOpened: 0,
    totalInstallmentsOpened: 0,
    totalInstallmentsPaid: 0,
  }

  installments.forEach(installment => {
    if (installment.status === InstallmentStatus.Paid) {
      total.amountPaid += installment.amount
      total.totalInstallmentsPaid++
    }

    if (installment.status === InstallmentStatus.Opened) {
      total.amountOpened += installment.amount
      total.totalInstallmentsOpened++
    }
  })

  return total
}

const formatToCurrency = (
  locale: string,
  obj: ResumeInstallments,
): ResumeInstallmentsFormatted => {
  return {
    ...obj,
    amountPaid: formatCurrency(locale, obj['amountPaid']),
    amountOpened: formatCurrency(locale, obj['amountOpened']),
  }
}

const resumeInstallmentsFormatted = (
  product: Product,
  locale: string,
): ResumeInstallmentsFormatted => {
  const objResumeInstallments = resumeInstallments(product)
  return formatToCurrency(locale, objResumeInstallments)
}

export { resumeInstallments, resumeInstallmentsFormatted }
