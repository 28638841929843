import { styled } from '@creditas-ui/system'
import {
  Box,
  ButtonSecondary,
  css,
  Flex,
  getTypography,
  selectTheme,
  Typography,
} from '@creditas-ui/react'

export const InformationCardContainer = styled(Flex)`
  align-items: flex-start;
  padding: 16px;
  border-radius: 4px;
  i > svg > g {
    stroke: ${selectTheme('colors.warning.60')};
  }
`

export const TextContainer = styled(Box)`
  padding-right: 16px;
`

export const TextContent = styled(Typography)``

export const LinkContent = styled(ButtonSecondary)`
  ${getTypography('bodySmBold')}
  padding: 0;
  text-decoration: none;
  display: inline-block;
`
