import React from 'react'
import BInfoPositive from '@creditas/brand-icons/dist/cS/BInfoPositive'
import { useTranslation } from 'react-i18next'

import {
  Card,
  CardTitle,
  CardDescription,
  CardGrid,
  CardContent,
  CardIcon,
  CardBtnSeePreviousItem,
} from '../Card'

import { DeniedCardLoanData } from '../../interfaces'

interface Props {
  data: DeniedCardLoanData
}

const LoanDeniedCard = ({
  data: { seePreviousItems, seePreviousItemsHref },
}: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardGrid>
        <CardIcon>
          <BInfoPositive fontSize="default" />
        </CardIcon>
        <CardContent>
          <CardTitle>{t('cards.LoanDeniedCard.title')}</CardTitle>
          <CardDescription>
            {t('cards.LoanDeniedCard.description')}
          </CardDescription>
        </CardContent>
      </CardGrid>

      {seePreviousItems && (
        <CardBtnSeePreviousItem
          onClick={_ => window.singleSpaNavigate(seePreviousItemsHref)}
        >
          {t('cards.LoanDeniedCard.action')}
        </CardBtnSeePreviousItem>
      )}
    </Card>
  )
}

export { LoanDeniedCard }
