import { Product, WaitingApprovalCardStoreData } from './../interfaces/'
import { formatCurrency } from '../../../helpers/'

const adaptStoreWaitingApprovalCard = (
  data: Product,
): WaitingApprovalCardStoreData => {
  return {
    productDescription: data.description,
    amount: formatCurrency('pt-BR', data.amount),
    totalInstallments: data.total_installments,
  }
}

export { adaptStoreWaitingApprovalCard }
