import { calcProgressBar, resumeInstallmentsFormatted } from '../helpers'
import { CurrentCardCreditCardData, Product } from '../interfaces'

const adaptCreditCardCurrentCard = (
  data: Product,
): CurrentCardCreditCardData => {
  const objResumeInstallments = resumeInstallmentsFormatted(data, 'pt-BR')
  const { installments, buttons, con_codigo } = data

  return {
    urlProductInternal: '/consignado/loan-details',
    progressBarPercentage: calcProgressBar(installments),
    ...objResumeInstallments,
    buttons: {
      installmentsAdvance: buttons.installments_advance,
      installmentsAdvanceUrl: `/consignado/early-payment/${con_codigo}`,
      refinance: buttons.refinance,
      refinanceUrl: '/consignado/new-simulation',
    },
  }
}

export { adaptCreditCardCurrentCard }
